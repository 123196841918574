import React, {  useEffect, useRef } from "react";
import NavBar from "../components/NavBar";
import HomeN from "../components/HomeN";
import About from "../components/About";
import Services from "../components/Services";
import Footer from "../components/Footer";
import Consulting from "../components/Consulting";
import { useThemeContext } from "../context/themeContext";
const MainPage = () => {
  const { darkMode } = useThemeContext();
  const svgRef = useRef();
 
  useEffect(() => {
    const handelChange = () => {
      const scrollTob = window.scrollY;
      if (scrollTob === 0) {
        svgRef.current.style.bottom = "-30rem";
        svgRef.current.style.transition = "all 5s ease-in-out";
      } else {
        svgRef.current.style.bottom = "1rem";
        svgRef.current.style.transition = "all 5s ease-in-out";
      }
    };
    window.addEventListener("scroll", handelChange);
    return () => window.removeEventListener("scroll", handelChange);
  }, []);
  return (
    <>
      <div
        className={` ${
          darkMode ? "mainbg1" : "mainBg"
        } ${"flex flex-col justify-between items-center flex-wrap w-screen overflow-x-hidden"}`}
      >
        <NavBar />
        <HomeN />
        <About />
        <Services />
        <Consulting />
        <Footer />

        <svg
          ref={svgRef}
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#00407E"
            d="M41.6,-58.5C55.1,-47.5,68.1,-36.9,75.7,-22.4C83.4,-8,85.9,10.4,77.7,22.1C69.6,33.8,50.8,39,36.1,50.4C21.4,61.7,10.7,79.4,-2.8,83.2C-16.3,87.1,-32.6,77.2,-42.9,64.4C-53.3,51.6,-57.7,35.9,-65.2,19.6C-72.6,3.3,-83.2,-13.6,-78.9,-25.3C-74.7,-37.1,-55.6,-43.7,-40,-54C-24.4,-64.2,-12.2,-78.1,0.9,-79.4C14.1,-80.7,28.2,-69.4,41.6,-58.5Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>
    </>
  );
};
export default MainPage;
